export const schema = {
	fields: [
		{
			name: 'id',
			component: 'FieldList',
			appearance: { default: { width: '100%' } },
			componentAttributes: {
				fields: [
					{
						name: 'name',
						mapper: 'translate',
						component: 'BoldText',
						appearance: { default: { fontSize: 'large' } },
						attributes: {
							isStatus: false,
							sortable: false,
							isDefaultSort: false,
							initialSortDirection: 'desc'
						},
						componentAttributes: { fontWeight: 'bold' },
						conditions: {
							showWhen: [
								[{ name: 'isNotEmpty', field: 'name' }],
								[{ name: 'isEqualTo', field: 'type', referenceValue: 'warehouse' }]
							]
						}
					},
					{
						name: 'displayId',
						component: 'Link',
						componentAttributes: {
							labelField: 'displayId',
							translateLabels: false,
							target: '_blank',
							labelMapper: 'addHashtag',
							path: '/delivery/shipping/edit/{id}',
							fontWeight: 'bold',
							endpointParameters: [{ name: 'id', target: 'path', value: { dynamic: 'id' } }]
						},
						conditions: {
							showWhen: [
								[{ name: 'isEmpty', field: 'name' }],
								[{ name: 'isEqualTo', field: 'type', referenceValue: 'shipping' }],
								[{ name: 'isEmpty', field: 'actions' }],
								[{ name: 'greaterThan', field: 'orders.length', referenceValue: 1 }]
							]
						}
					},
					{
						name: 'displayId',
						component: 'Link',
						componentAttributes: {
							labelField: 'orders[0].commerceId',
							translateLabels: false,
							target: '_blank',
							labelMapper: 'addHashtag',
							path: '/oms/order/edit/{id}',
							fontWeight: 'bold',
							endpointParameters: [
								{ name: 'id', target: 'path', value: { dynamic: 'orders[0].id' } }
							]
						},
						conditions: {
							showWhen: [
								[{ name: 'isEmpty', field: 'name' }],
								[{ name: 'isEqualTo', field: 'type', referenceValue: 'shipping' }],
								[{ name: 'isEmpty', field: 'actions' }],
								[{ name: 'isEqualTo', field: 'orders.length', referenceValue: 1 }]
							]
						}
					},
					{
						name: 'actions',
						component: 'MultiValueWrapper',
						appearance: { default: { width: '100%' } },
						conditions: {
							showWhen: [
								[{ name: 'isNotEmpty', field: 'actions' }],
								[{ name: 'isEqualTo', field: 'type', referenceValue: 'shipping' }]
							]
						},
						componentAttributes: {
							useDataField: true,
							field: {
								component: 'FieldList',
								componentAttributes: {
									direction: 'horizontal',
									fields: [
										{
											component: 'FieldList',
											appearance: { default: { width: '88%' } },
											componentAttributes: {
												fields: [
													{
														name: 'displayId',
														appearance: { default: { fontSize: 'large', width: '140px' } },
														component: 'BoldText',
														componentAttributes: { fontWeight: 'bold' },
														mapper: 'addHashtag',
														conditions: {
															showWhen: [
																[{ name: 'greaterThan', field: 'orders.length', referenceValue: 1 }]
															]
														}
													},
													{
														name: 'displayId',
														component: 'Link',
														componentAttributes: {
															labelField: 'orders[0].commerceId',
															translateLabels: false,
															labelMapper: 'addHashtag',
															target: '_blank',
															path: '/oms/order/edit/{id}',
															fontWeight: 'bold',
															endpointParameters: [
																{ name: 'id', target: 'path', value: { dynamic: 'orders[0].id' } }
															]
														},
														conditions: {
															showWhen: [
																[{ name: 'isEqualTo', field: 'orders.length', referenceValue: 1 }]
															]
														}
													},
													{
														component: 'FieldList',
														componentAttributes: {
															direction: 'horizontal',
															fields: [
																{
																	name: 'type',
																	mapper: 'translate',
																	component: 'Text',
																	appearance: { default: { fontSize: 'medium' } },
																	attributes: {
																		isStatus: false,
																		sortable: false,
																		isDefaultSort: false,
																		initialSortDirection: 'desc'
																	},
																	componentAttributes: { fontWeight: 'medium' }
																},
																{
																	name: 'date',
																	component: 'Text',
																	appearance: { default: { fontSize: 'medium' } },
																	attributes: {
																		isStatus: false,
																		sortable: false,
																		isDefaultSort: false,
																		initialSortDirection: 'desc'
																	},
																	componentAttributes: { fontWeight: 'medium' }
																}
															]
														}
													}
												]
											}
										},
										{
											component: 'FieldList',
											appearance: { default: { width: '10%', padding: '5px' } },
											componentAttributes: {
												fields: [
													{
														name: 'packageQuantity',
														component: 'Chip',
														componentAttributes: {
															icon: 'basket',
															iconSize: 24,
															iconColor: 'black',
															borderColor: 'transparent'
														}
													}
												]
											}
										}
									]
								}
							}
						}
					},
					{
						component: 'FieldList',
						conditions: {
							showWhen: [
								[
									{ name: 'isEqualTo', field: 'type', referenceValue: 'warehouse' },
									{ name: 'isEmpty', field: 'actions' }
								]
							]
						},
						componentAttributes: {
							fields: [
								{
									name: 'icon',
									component: 'Icon',
									appearance: { default: { width: '24px' } },
									componentAttributes: { icon: 'box_arrow_left', color: 'blue' },
									attributes: {
										isStatus: false,
										sortable: false,
										isDefaultSort: false,
										initialSortDirection: 'desc'
									},
									conditions: {
										showWhen: [
											[{ name: 'isNotEqualTo', field: 'type', referenceValue: 'shipping' }]
										]
									}
								},
								{
									name: 'action',
									mapper: 'translate',
									component: 'Text',
									appearance: { default: { fontSize: 'medium' } },
									attributes: {
										isStatus: false,
										sortable: false,
										isDefaultSort: false,
										initialSortDirection: 'desc'
									},
									componentAttributes: { fontWeight: 'medium' }
								}
							],
							direction: 'horizontal'
						},
						attributes: {
							isStatus: false,
							sortable: false,
							isDefaultSort: false,
							initialSortDirection: 'desc'
						}
					}
				]
			},
			attributes: {
				isStatus: false,
				sortable: false,
				isDefaultSort: false,
				initialSortDirection: 'desc'
			}
		}
	],
	warehouseMarkers: {
		icon: 'warehouse',
		size: '24',
		infoSchema: {
			fieldsGroup: [
				{
					name: 'title',
					fields: [
						{
							name: 'name',
							component: 'Link',
							componentAttributes: {
								labelField: 'name',
								translateLabels: false,
								path: '/wms/warehouse/edit/{id}',
								endpointParameters: [{ name: 'id', target: 'path', value: { dynamic: 'id' } }]
							}
						}
					]
				}
			]
		}
	},
	shippingMarkers: {
		icon: 'user_closed',
		size: '24',
		color: 'white',
		infoSchema: {
			fieldsGroup: [
				{
					name: 'location',
					fields: [
						{
							name: 'address',
							icon: 'location_marker',
							component: 'Text',
							mapper: {
								name: 'template',
								props: {
									template: '{0} {1} {2}, {3} ({4}), {5}, {6}, {7}',
									fields: [
										{
											name: 'dropoff.street',
											mapper: [{ name: 'sufix', props: { value: ' ' } }],
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										},
										{
											name: 'dropoff.number',
											mapper: [{ name: 'sufix', props: { value: ' ' } }],
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										},
										{
											name: 'dropoff.complement',
											mapper: [{ name: 'sufix', props: { value: ', ' } }],
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										},
										{
											name: 'dropoff.neighborhood',
											mapper: [{ name: 'sufix', props: { value: ' ' } }],
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										},
										{
											name: 'dropoff.postalCode',
											mapper: [{ name: 'sufix', props: { value: '), ' } }],
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										},
										{
											name: 'dropoff.state',
											mapper: [{ name: 'sufix', props: { value: ', ' } }],
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										},
										{
											name: 'dropoff.city',
											mapper: [{ name: 'sufix', props: { value: ', ' } }],
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										},
										{
											name: 'dropoff.country',
											conditions: { showWhen: [[{ name: 'isNotEmpty' }]] }
										}
									]
								}
							}
						}
					]
				},
				{
					name: 'receiver',
					fields: [{ name: 'receiver.fullname', component: 'Text', icon: 'user_closed' }]
				}
			]
		}
	},
	creationStep: {
		transportationData: {
			fields: [
				{
					name: 'driverId',
					component: 'Select',
					componentAttributes: {
						translateLabels: false,
						options: {
							scope: 'remote',
							endpoint: {
								service: 'tms',
								namespace: 'driver-available',
								method: 'list'
							},
							endpointParameters: [],
							valuesMapper: {
								value: 'id',
								label: {
									template: '{0} {1}',
									fields: ['firstname', 'lastname']
								}
							}
						}
					}
				},
				{
					name: 'deliveryManId',
					component: 'Select',
					componentAttributes: {
						translateLabels: false,
						options: {
							scope: 'remote',
							endpoint: {
								service: 'tms',
								namespace: 'delivery-man',
								method: 'list'
							},
							endpointParameters: [
								{
									name: 'status',
									target: 'filter',
									value: { static: 'active' }
								}
							],
							valuesMapper: {
								value: 'id',
								label: {
									template: '{0} {1}',
									fields: ['name', 'lastName']
								}
							}
						}
					}
				},
				{
					name: 'vehicleId',
					component: 'Select',
					componentAttributes: {
						translateLabels: false,
						options: {
							scope: 'remote',
							endpoint: {
								service: 'tms',
								namespace: 'vehicle',
								method: 'list'
							},
							endpointParameters: [
								{
									name: 'status',
									target: 'filter',
									value: { static: 'active' }
								}
							]
						},
						valuesMapper: {
							value: 'id',
							label: {
								template: '{0}{1}{2}',
								fields: [
									{ name: 'name' },
									{
										name: 'brand',
										mapper: [{ name: 'prefix', props: { value: ' (' } }],
										conditions: { showWhen: [[{ name: 'isNotEmpty', field: 'brand' }]] }
									},
									{
										name: 'model',
										mapper: [
											{ name: 'prefix', props: { value: ' ' } },
											{ name: 'sufix', props: { value: ')' } }
										],
										conditions: { showWhen: [[{ name: 'isNotEmpty', field: 'brand' }]] }
									}
								]
							}
						}
					}
				}
			]
		},
		stopsData: {
			source: {
				service: 'tms',
				namespace: 'route',
				method: 'simulate'
			}
		}
	},
	target: {
		service: 'tms',
		namespace: 'route',
		method: 'create'
	}
};
