import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import alerts from './alerts';
import asyncDependencies from './asyncDependencies';
import browses from './browseSection';
import createRoute from './createRoute';
import createRouteV2 from './createRouteV2';
import editRoute from './editRoute';
import editCreate from './editCreate';
import formSections from './formSection';
import filters from './filters';
import logs from './logs';
import menu from './menu';
import monitor from './monitor';
import navbar from './navbar';
import page from './page';
import preview from './preview';
import user from './user';
import planning from './planning';

const reducer = combineReducers({
	alerts,
	asyncDependencies,
	browses,
	createRoute,
	planning,
	createRouteV2,
	editRoute,
	editCreate,
	filters,
	form: reduxFormReducer,
	formSections,
	logs,
	menu,
	monitor,
	navbar,
	page,
	preview,
	user
});

export default reducer;
