import { get } from 'lodash';
import store from 'store';
import Decimal from 'decimal.js';
import { getAuthCookieDecoded } from './auth';

/**
 * Format number to string currency
 * @param {number} value
 * @param {object} props
 * @param {object} data
 * @returns {string}
 */
export const numberToCurrency = (value, props = {}, data = {}) => {
	const { currencyField } = props;

	if (typeof value === 'undefined' || value === null) return value;

	const { tcurrency, tcurrencyDisplay, locale } = getAuthCookieDecoded() || {};

	const localeSelected = store.get('currentLanguage') || locale || undefined;

	let formatter;

	try {
		formatter = new Intl.NumberFormat(localeSelected, {
			style: 'currency',
			currency: get(data, currencyField || 'currency') || tcurrency,
			currencyDisplay: tcurrencyDisplay || 'symbol'
		});
	} catch {
		formatter = new Intl.NumberFormat(localeSelected, {
			style: 'currency',
			currency: 'USD'
		});
	}

	return formatter.format(value);
};

/**
 * Format number to string number formmatted
 * @param {number} value
 * @returns {string}
 */
export const formatNumber = value => new Intl.NumberFormat().format(value);

/**
 * Fixed number value
 * @param {number} value
 * @param {number} digits
 * @returns {number}
 */
export const toFixed = (value, digits) =>
	typeof value === 'number' ? parseFloat(value.toFixed(digits)) : value;

export const sum = (firstValue, secondValue) => Decimal.sum(firstValue, secondValue).toNumber();

export const sub = (firstValue, secondValue) => Decimal.sub(firstValue, secondValue).toNumber();

export const isNumber = num => typeof num === 'number' && !Number.isNaN(Number(num));
