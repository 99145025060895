export const FETCH_DATA_REQUEST = 'editCreate/FETCH_DATA_REQUEST';
export const FETCH_DATA_FAILURE = 'editCreate/FETCH_DATA_FAILURE';
export const FETCH_DATA_SUCCESS = 'editCreate/FETCH_DATA_SUCCESS';
export const MODAL_OPEN = 'editCreate/MODAL_OPEN';
export const MODAL_CLOSE = 'editCreate/MODAL_CLOSE';
export const SAVE_DATA_REQUEST = 'editCreate/SAVE_DATA_REQUEST';
export const SAVE_DATA_FAILURE = 'editCreate/SAVE_DATA_FAILURE';
export const SAVE_DATA_SUCCESS = 'editCreate/SAVE_DATA_SUCCESS';
export const SET_NAVIGATION_FLAG = 'editCreate/SET_NAVIGATION_FLAG';
export const IS_READY = 'editCreate/IS_READY';
export const SET_TAB_VISIBLE = 'editCreate/SET_TAB_VISIBLE';
export const TOGGLE_ALL_COLLAPSERS = 'editCreate/TOGGLE_ALL_COLLAPSERS';
export const SHOW_QR_CODE = 'editCreate/SHOW_QR_CODE';
export const SET_SAVE_EDIT_DEPENDENCIES = 'editCreate/SET_SAVE_EDIT_DEPENDENCIES';
