import * as types from './types';
import * as pageTypes from '../page/types';

const initialState = {
	data: {},
	error: false,
	isFetching: false,
	isReady: false,
	isModalOpen: false,
	visibleTab: '',
	errorData: {},
	saveEditDependencies: {}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case pageTypes.CHANGE_PAGE:
			return { ...initialState };

		case types.IS_READY:
			return { ...state, isReady: action.isReady };

		case types.FETCH_DATA_REQUEST:
			return { ...state, error: false, isFetching: true, isReady: false };

		case types.FETCH_DATA_SUCCESS:
			return { ...state, data: action.data, error: false, isFetching: false, isReady: true };

		case types.FETCH_DATA_FAILURE:
			return { ...state, error: true, isFetching: false, errorData: action.status, isReady: true };

		case types.MODAL_OPEN:
			return { ...state, isModalOpen: true };

		case types.MODAL_CLOSE:
			return { ...state, isModalOpen: false };

		case types.SAVE_DATA_REQUEST:
			return { ...state, isFetching: true };

		case types.SAVE_DATA_SUCCESS:
			return { ...state, isFetching: false };

		case types.SAVE_DATA_FAILURE:
			return { ...state, isFetching: false };

		case types.SET_TAB_VISIBLE:
			return { ...state, visibleTab: action.name };

		case types.TOGGLE_ALL_COLLAPSERS: {
			return { ...state, allCollapsersOpen: action.status };
		}
		case types.SHOW_QR_CODE: {
			return { ...state, showQRCode: action.status };
		}
		case types.SET_SAVE_EDIT_DEPENDENCIES: {
			return {
				...state,
				saveEditDependencies: { ...state.saveEditDependencies, ...action.value }
			};
		}

		default:
			return state;
	}
}
