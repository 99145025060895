export const CREATE_PAGE = 'createRoute/CREATE_PAGE';
export const FETCH_MAIN_DATA_SUCCESS = 'createRoute/FETCH_MAIN_DATA_SUCCESS';
export const CLEAN_LIST_DATA = 'createRoute/CLEAN_LIST_DATA';
export const SET_LOADING = 'createRoute/SET_LOADING';
export const SET_ERROR = 'createRoute/SET_ERROR';
export const CHANGE_SELECTED_ROWS = 'createRoute/CHANGE_SELECTED_ROWS';
export const CHANGE_STEP = 'createRoute/CHANGE_STEP';
export const SEND_DRIVER_DATA = 'createRoute/SEND_DRIVER_DATA ';
export const GO_BACK = 'createRoute/GO_BACK';
export const CLEAN_STATE = 'createRoute/CLEAN_STATE';
export const STOPS_COLOR = 'createRoute/STOPS_COLOR';
export const SET_STOPS = 'createRoute/SET_STOPS';
export const SET_ROUTE_DATA = 'createRoute/SET_ROUTE_DATA';
export const SET_SCHEDULE = 'createRoute/SET_SCHEDULE';
