import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { getValueForSelect, parseValue, makePrefixedValue } from 'utils';
import { getLocationParts } from 'utils/location';
import { translateHelperString } from 'utils/translate';
import { translateHelperComponent, makeLabelValue } from 'utils/translateComponent';
import { isTranslationKey } from 'utils/string';
import { last, camelCase, capitalize, isEqual } from 'lodash';
import { handleRedirect } from 'utils/redirect';

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getStatePage = state => state.page;

export const getSchema = state => state.page.schema;

export const getCurrentProps = (...args) => last(args);

const isEmptyValue = value => value === '' || value === null || value === undefined;

const getCurrentValue = (state, props) => {
	const filterApplied = state.appliedFilters[props.name];

	const isSelect = /select/gi.test(props.component);

	if (isSelect && Array.isArray(filterApplied)) return filterApplied.map(parseValue);

	return isSelect ? parseValue(filterApplied) : filterApplied;
};

export const getFilterValue = () =>
	createSelector(
		[getCurrentValue, getCurrentProps],
		(value, { name, component, remote, componentAttributes }) => {
			if (isEmptyValue(value)) return;

			const isSelect = /select/gi.test(component);

			if (!isSelect) return value;

			const { translateLabels, labelPrefix, options = {} } = componentAttributes;

			const translate = (val, customPrefix) => {
				const translateLabel = translateLabels || !!customPrefix;

				const prefixes = labelPrefix || customPrefix;

				const label = makePrefixedValue(name, val.label, translateLabel, prefixes);

				const formattedOption = { ...val, label };

				return translateLabel ? getValueForSelect(formattedOption) : formattedOption;
			};

			const verifyValue = currentValue => {
				const formatValue = rawValue => {
					if (typeof rawValue !== 'object') return { label: rawValue.toString(), value: rawValue };
					return rawValue;
				};

				if (Array.isArray(currentValue)) return currentValue.map(formatValue);

				return formatValue(currentValue);
			};

			if (options && !remote) {
				const optionsArray = Array.isArray(options) ? options : options.values;

				const findValueInOptions = itemValue => {
					if (!Array.isArray(optionsArray)) return;

					return optionsArray.find(opt => {
						const optionParsed = parseValue(opt.value);

						return optionParsed === itemValue;
					});
				};

				if (typeof value !== 'object') {
					const filterOption = findValueInOptions(value);

					if (filterOption) return translate(filterOption);
				}

				if (Array.isArray(value)) {
					if (!Array.isArray(optionsArray)) return value.map(verifyValue);

					return value.map(val => {
						const { value: valueMapper = 'value' } = options.valuesMapper || {};

						const currentValue = typeof val === 'object' ? val[valueMapper] : val;

						const filterOption = findValueInOptions(currentValue);

						if (filterOption) return translate(filterOption);

						return verifyValue(val);
					});
				}
			}

			return verifyValue(value);
		}
	);

/**
 * Get current parsed and translated options for Filter Select
 * @param {object} currentProps
 */
export const getFilterOptions = () =>
	createSelector(
		[getCurrentProps],
		({ componentAttributes, remoteFieldLabel, currentValue, input = {}, name }) => {
			const { options, translateLabels, labelPrefix } = componentAttributes;

			if (remoteFieldLabel && currentValue) {
				const currentOptions = [];

				if (Array.isArray(currentValue)) {
					options.values = currentValue;
				} else {
					currentOptions.push(currentValue);
					options.values = currentOptions;
				}
			}

			if (!options) return;

			const optionsArray = Array.isArray(options) ? options : options.values;

			return (
				optionsArray &&
				optionsArray.map(option => {
					const fieldName = name || input.name;

					const label = makeLabelValue(fieldName, option.label, translateLabels, labelPrefix);
					const rawLabel = makeLabelValue(
						fieldName,
						option.label,
						translateLabels,
						labelPrefix,
						false
					);

					return { ...option, label, rawLabel };
				})
			);
		}
	);

/**
 * Get current page title key for translate
 */
export const getPageTitle = createSelector(
	[
		getSchema,
		(state, useSchemaName) => {
			const [, , method] = getLocationParts();
			return useSchemaName ? method : '';
		}
	],
	(schema = {}, schemaName) => {
		const { title, root, source, name } = schema;
		// Return title if exist in schema
		if (title) {
			const isValidTranslationKey = isTranslationKey(title);
			if (isValidTranslationKey) return title;

			return `common.title.${camelCase(title)}`;
		}

		if (source) {
			const { namespace } = source;

			// Create a default title if not exist title in schema
			const entity = camelCase(namespace);
			const schemaNameParsed = capitalize(camelCase(schemaName));

			return `common.title.${entity}${schemaNameParsed}${capitalize(root)}`;
		}

		return `common.title.${camelCase(name)}`;
	}
);

/**
 * Translate label value if exist label or create key for default translation
 * @param {object} data
 * @param {string} translationScope
 * @param {boolean} returnComponent
 * @returns
 */
export const getLabelValue = (data, translationScope = 'field', returnComponent = true) => {
	const { translateLabel = true, label, name, noLabel, component, componentAttributes = {} } = data;

	if (noLabel) return;

	const translateHelper = returnComponent ? translateHelperComponent : translateHelperString;

	if (isTranslationKey(label)) return translateHelper(label);

	const currentName = () => {
		if (component === 'MultiValueWrapper') {
			const {
				field: { label: multiValueLabel, name: multiValueName }
			} = componentAttributes;

			return multiValueLabel || multiValueName;
		}

		return label || name;
	};

	return translateLabel
		? translateHelper(`views.${translationScope}.${currentName()}`)
		: currentName();
};

export const getRedirect = () =>
	createSelector(
		[getSchema],
		({ redirect }) => {
			const id = getLocationParts()[3];
			if (redirect) handleRedirect(redirect, id);
		}
	);

// Get sections for forms
export const getSections = () =>
	createSelector(
		[getSchema],
		schema => {
			const { sections, redirect } = schema;
			if (redirect) return null;
			return sections.map(section => {
				const currentSection = { ...section };
				const { title, name } = section;

				if (isTranslationKey(title)) {
					currentSection.title = translateHelperComponent(title);
					return currentSection;
				}

				const titleKeys = `views.tab.${title || name}`;

				currentSection.title = translateHelperComponent(titleKeys);

				return currentSection;
			});
		}
	);
/**
 * Make fields label prop
 * @param {array} fields
 * @param {object} schema
 * @returns {array}
 */
const makeLabelFields = (fields, schema) =>
	fields.map(field => {
		const { componentAttributes = {}, component } = field;
		const { field: innerField, fields: innerFields } = componentAttributes;

		const getLabel = fieldData => getLabelValue(fieldData);

		const getInnerFields = () => {
			if (component === 'SelectForm') return;

			if (innerField) {
				const [innerFieldProps] = makeLabelFields([innerField], schema);

				return {
					field: {
						...innerFieldProps,
						label: getLabel(component === 'AsyncWrapper' ? field : innerField)
					}
				};
			}

			if (innerFields) return { fields: makeLabelFields(innerFields, schema) };
		};

		return {
			...field,
			componentAttributes: {
				...componentAttributes,
				...getInnerFields()
			},
			label: getLabel(field)
		};
	});

/**
 * Make a list fields with label prop
 * @param {function} getCustomSchema - selector fn for get custom schema
 * @returns {array}
 */
export const getFieldsWithLabels = getCustomSchema => {
	const selectors = [getCurrentProps, getSchema];

	if (getCustomSchema) selectors.push(getCustomSchema);

	return createSelector(
		selectors,
		(props, globalSchema, customSchema) =>
			makeLabelFields(props.fields, customSchema || globalSchema)
	);
};

/**
 * Make a group with label prop
 * @param {function} getCustomSchema - selector fn for get custom schema
 * @returns {object}
 */
export const getGroupWithLabel = () =>
	createSelector(
		[getCurrentProps],
		props => getLabelValue(props, 'fieldsGroup')
	);

// Format each sorting option and translate its labels
const formatSortingOption = (field, initialSortDirection, isDefaultSort) => {
	const label = getLabelValue(field);

	return {
		value: field.name,
		label,
		initialSortDirection,
		isDefaultSort
	};
};

/**
 * Maker options for Sorting component
 * @param {boolean} useFields - take fields for make sorting options
 * @returns
 */
export const makeSortingOptions = (useFields = false) =>
	createSelector(
		[getCurrentProps],
		schema => {
			const { fields = [], sortableFields = [] } = schema || {};

			/* Sorting rules from Schema */
			const sortableFieldsOptions = sortableFields.map(field =>
				formatSortingOption(field, field.initialSortDirection, field.isDefaultSort)
			);

			if (!useFields) return sortableFieldsOptions;

			/* Sorting rules from fields */
			const sortingByField = fields.reduce((accum, field) => {
				const { attributes = {} } = field;
				const { sortable, initialSortDirection, isDefaultSort } = attributes;

				if (sortable) {
					return [...accum, formatSortingOption(field, initialSortDirection, isDefaultSort)];
				}
				return accum;
			}, []);

			return [...sortableFieldsOptions, ...sortingByField];
		}
	);
