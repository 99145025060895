import * as types from './types';

export const initialError = { hasError: false, errorData: {} };

const initialState = {
	schema: {},
	mainInfo: {
		data: {},
		selectedRows: [],
		isLoading: true,
		driverData: {},
		errorStatus: initialError,
		stopsColorByWarehouseId: {},
		stops: [],
		routeData: {},
		shippingsToChange: {
			changeShippingsKey: '',
			shippings: []
		},
		selectedSchedule: {}
	}
};

/**
 *
 * @param {object} currentProperty fields to change into state with the value
 * @param {string} section section state name (warehouseInfo || mainInfo || schema)
 * @param {object} state current state
 * @returns {object} state
 */
const mergeState = (state, action, properties) => ({
	...state,
	[action.section]: {
		...state[action.section],
		...properties
	}
});

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.CREATE_PAGE:
			return { ...state, schema: action.schema };

		case types.FETCH_ROUTE_DATA_SUCCESS:
			return mergeState(state, { section: 'mainInfo' }, { data: action.data });

		case types.SET_LOADING:
			return mergeState(state, action, {
				errorStatus: initialError,
				isLoading: action.isLoading
			});

		case types.SET_STOPS:
			return mergeState(state, action, { stops: action.stops });

		case types.CHANGE_SELECTED_ROWS:
			return mergeState(state, { section: 'mainInfo' }, { selectedRows: action.selectedRows });

		case types.SAVE_DRIVER_DATA:
			return mergeState(state, { section: 'mainInfo' }, { driverData: action.driverData });

		case types.SET_ERROR:
			return mergeState(state, action, {
				isLoading: false,
				errorStatus: { hasError: true, errorData: action.status }
			});

		case types.SAVE_STOPS_COLOR:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{ stopsColorByWarehouseId: action.payload }
			);

		case types.SET_ROUTE_DATA:
			return mergeState(state, action, {
				routeData: action.routeData
			});

		case types.CHANGE_SHIPPINGS:
			return mergeState(
				state,
				{ section: 'mainInfo' },
				{
					shippingsToChange: action.payload
				}
			);

		case types.SET_SCHEDULE:
			return mergeState(state, { section: 'mainInfo' }, { selectedSchedule: action.schedule });

		default:
			return state;
	}
}
