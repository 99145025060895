import { getPageData } from 'utils/api';
import { backToBrowse, getLocationParts, goToURL } from 'utils/location';
import { getPathFiltersParameters, getQueryFiltersParameters, callRequest } from 'utils/request';
import { isEqual, merge, get as lodashGet, omitBy } from 'lodash';
import { actions as asyncDependenciesActions } from 'modules/asyncDependencies';
import * as types from './types';
import { setRemoteActionsCache, fetchRemoteSections } from '../page/actions';
import { getEditDependencies } from './selectors';

const fetchDataRequest = () => ({
	type: types.FETCH_DATA_REQUEST
});

export const fetchDataSuccess = data => ({
	type: types.FETCH_DATA_SUCCESS,
	data
});

const fetchDataFailure = status => ({
	type: types.FETCH_DATA_FAILURE,
	status
});

const openModal = () => ({
	type: types.MODAL_OPEN
});

export const closeModal = () => ({
	type: types.MODAL_CLOSE
});

export const setReady = isReady => ({
	type: types.IS_READY,
	isReady
});

export const updateData = newDataProps => (dispatch, getState) => {
	const { editCreate: state } = getState();

	const updatedData = merge(state.data, newDataProps);

	dispatch(fetchDataSuccess(updatedData));
};

const fetchRemoteActions = async (dispatch, remoteActionsSource, data) => {
	const {
		source,
		sourceEndpointParameters = [],
		title,
		translateTitle,
		modalSize
	} = remoteActionsSource;

	const endpointParameters = getPathFiltersParameters(sourceEndpointParameters, data);

	const filters = getQueryFiltersParameters(sourceEndpointParameters, data, true);

	try {
		const actions = await callRequest(source, filters, endpointParameters);

		const remoteActions = { actions, titleSettings: { title, translateTitle }, modalSize };

		dispatch(setRemoteActionsCache(remoteActions));
	} catch (error) {
		console.error(error);
	}
};

export const fetchData = () => async (dispatch, getState) => {
	const {
		editCreate: state,
		page: {
			schema: { source, remoteActions, dependencies }
		}
	} = getState();

	if (!source) return dispatch(fetchDataSuccess({}));

	if (state.isFetching) return false;

	/* Add the current id to the provided source url */
	const id = getLocationParts()[3];

	dispatch(fetchDataRequest());

	try {
		const data = await getPageData({ source, endpointParameters: { id } });

		dispatch(fetchDataSuccess(data));
		dispatch(fetchRemoteSections());

		dispatch(
			asyncDependenciesActions.executeGetAsyncDependencies(
				dependencies,
				data,
				'globalDependencies',
				'edit'
			)
		);
		/** Get remote actions and add them to the store */
		if (remoteActions) fetchRemoteActions(dispatch, remoteActions, data);
	} catch ({ response = {} }) {
		dispatch(fetchDataFailure({ status: response.status }));
	}
};

export const setSaveEditDependencies = value => ({
	type: types.SET_SAVE_EDIT_DEPENDENCIES,
	value
});

/** Checks if changes have been made (comparing the form's current values with the initial ones).
 * If changes are detected, the confirmation modal will be shown.
 * If not, the user will be redirected to the Browse view.
 */
export const cancel = () => formSection => (dispatch, getState) => {
	const { form, formSections, page, editCreate } = getState();
	const { initial, values } = lodashGet(form, formSection) || {};
	const { [formSection]: currentFormSection = {} } = formSections;
	const { navigationFlag } = currentFormSection;

	const {
		schema: { cancelRedirectUrl }
	} = page;

	const goToRedirectUrl = cancelRedirectUrl && navigationFlag !== 'redirectToBrowseEntity';
	const editDependencies = getEditDependencies(editCreate);
	const differences = omitBy(values, (value, key) => isEqual(value, initial[key]));

	if (!initial || !values || navigationFlag === 'apply') {
		return goToRedirectUrl ? goToURL(cancelRedirectUrl) : backToBrowse(navigationFlag);
	}

	if (isEqual(initial, values)) {
		return goToRedirectUrl ? goToURL(cancelRedirectUrl) : backToBrowse(navigationFlag);
	}
	if (editDependencies && differences && isEqual(editDependencies, differences)) {
		return goToRedirectUrl ? goToURL(cancelRedirectUrl) : backToBrowse(navigationFlag);
	}
	dispatch(openModal());
};

/**
 * Set current tab active in edit page
 * Pass a object with name for se actuve tab
 * @param {string} name
 */
export const setTabVisible = ({ name }) => ({
	type: types.SET_TAB_VISIBLE,
	name
});

/** Open or close all visible collapsers according to the new status
 * @param {boolean} status - meaning if all collapsers should be open (true) or closed (false)
 */
export const toggleAllCollapsers = status => ({
	type: types.TOGGLE_ALL_COLLAPSERS,
	status
});
/** Open or close all visible collapsers according to the new status
 * @param {boolean} status - meaning if all collapsers should be open (true) or closed (false)
 */
export const toggleShowQR = status => ({
	type: types.SHOW_QR_CODE,
	status
});

export const setAsyncGlobalDependencies = dependency => (dispatch, getState) => {
	const {
		editCreate: {
			data: { globalDependencies }
		}
	} = getState();

	dispatch(updateData({ globalDependencies: { ...globalDependencies, ...dependency } }));
};
