import isEmail from 'validator/lib/isEmail';
import { get as lodashGet } from 'lodash';
import moment from 'moment';

export const required = value =>
	(value && typeof value === 'object'
	? Object.keys(value).length
	: value)
		? undefined
		: 'views.validation.isRequired';

export const email = value =>
	value && !isEmail(value) ? 'views.validation.shouldBeAnEmail' : undefined;

export const minLength = min => value =>
	value && value.length < min ? 'views.validation.shouldHaveAMinimumLength' : undefined;

export const maxLength = max => value =>
	value && value.length > max ? 'views.validation.shouldNotExceedTheMaximumLength' : undefined;

export const minValue = min => value =>
	Number(value) < Number(min) ? 'views.validation.mustExceedMinimumValue' : undefined;

export const maxValue = max => value =>
	Number(value) > Number(max) ? 'views.validation.shouldNotExceedTheMaximumValue' : undefined;

export const numeric = value =>
	Number.isNaN(Number(value)) ? 'views.validation.shouldBeANumber' : undefined;

export const integer = value =>
	value && !Number.isInteger(Number(value))
		? 'views.validation.numberShouldBeAnInteger'
		: undefined;

export const positive = value =>
	Math.sign(value) <= 0 ? 'views.validation.numberShouldBePositive' : undefined;

export const literal = (match, referenceValueType) => (value, allValues) => {
	const referenceValue = referenceValueType === 'dynamic' ? lodashGet(allValues, match) : match;
	return value !== referenceValue ? `views.validation.valueMustMatch ${match}` : undefined;
};

export const validTimeRange = value => {
	const { from, to } = value;
	return moment(from).isAfter(to) ? 'views.validation.invalidTimeRange' : undefined;
};
