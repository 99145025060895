export const schema = {
	source: {
		service: 'tms',
		namespace: 'route',
		method: 'get'
	},
	endpointParameters: [
		{
			name: 'fields',
			target: 'queryString',
			value: {
				static: ['displayId', 'driverId', 'deliveryManId', 'vehicleId']
			}
		}
	],
	editStep: {
		transportationData: {
			fields: [
				{
					name: 'driverId',
					component: 'Select',
					componentAttributes: {
						translateLabels: false,
						options: {
							scope: 'remote',
							endpoint: {
								service: 'tms',
								namespace: 'driver-available',
								method: 'list'
							},
							endpointParameters: [],
							valuesMapper: {
								value: 'id',
								label: {
									template: '{0} {1}',
									fields: ['firstname', 'lastname']
								}
							}
						}
					}
				},
				{
					name: 'deliveryManId',
					component: 'Select',
					componentAttributes: {
						translateLabels: false,
						options: {
							scope: 'remote',
							endpoint: {
								service: 'tms',
								namespace: 'delivery-man',
								method: 'list'
							},
							endpointParameters: [
								{
									name: 'status',
									target: 'filter',
									value: {
										static: 'active'
									}
								}
							],
							valuesMapper: {
								value: 'id',
								label: {
									template: '{0} {1}',
									fields: ['name', 'lastName']
								}
							}
						}
					}
				},
				{
					name: 'vehicleId',
					component: 'Select',
					componentAttributes: {
						translateLabels: false,
						options: {
							scope: 'remote',
							endpoint: {
								service: 'tms',
								namespace: 'vehicle',
								method: 'list'
							},
							endpointParameters: [
								{
									name: 'status',
									target: 'filter',
									value: { static: 'active' }
								}
							]
						},
						valuesMapper: {
							value: 'id',
							label: {
								template: '{0}{1}{2}',
								fields: [
									{ name: 'name' },
									{
										name: 'brand',
										mapper: [{ name: 'prefix', props: { value: ' (' } }],
										conditions: {
											showWhen: [[{ name: 'isNotEmpty', field: 'brand' }]]
										}
									},
									{
										name: 'model',
										mapper: [
											{ name: 'prefix', props: { value: ' ' } },
											{ name: 'sufix', props: { value: ')' } }
										],
										conditions: {
											showWhen: [[{ name: 'isNotEmpty', field: 'brand' }]]
										}
									}
								]
							}
						}
					}
				}
			]
		},
		shippingsData: {
			fields: [
				{
					name: 'shipping',
					component: 'Multiselect',
					width: 100,
					componentAttributes: {
						translateLabels: false,
						options: {
							scope: 'remote',
							endpoint: {
								service: 'delivery',
								namespace: 'shipping',
								method: 'list'
							},
							endpointParameters: [
								{
									name: 'routePending',
									target: 'filter',
									value: {
										static: 'true'
									}
								},
								{
									name: 'hasIntegration',
									target: 'filter',
									value: {
										static: 'false'
									}
								},
								{
									name: 'status',
									target: 'filter',
									value: {
										static: ['created', 'scheduled']
									}
								}
							],
							valuesMapper: {
								label: {
									template: '{0}',
									fields: ['displayId', 'id']
								},
								value: 'id'
							},
							searchParam: 'filters[displayId]'
						}
					}
				}
			]
		},
		stopsData: {
			source: {
				service: 'tms',
				namespace: 'route',
				method: 'simulate'
			},
			endpointParameters: [
				{
					name: 'id',
					target: 'path',
					value: {
						dynamic: 'id'
					}
				}
			]
		}
	},
	dependencies: [
		{
			name: 'driverId',
			source: {
				service: 'tms',
				namespace: 'driver',
				method: 'list'
			},
			endpointParameters: [
				{
					name: 'id',
					target: 'filter',
					value: {
						dynamic: 'driverId'
					}
				}
			],
			targetField: 'driverData'
		},
		{
			name: 'deliveryManId',
			source: {
				service: 'tms',
				namespace: 'delivery-man',
				method: 'list'
			},
			endpointParameters: [
				{
					name: 'id',
					target: 'filter',
					value: {
						dynamic: 'deliveryManId'
					}
				}
			],
			targetField: 'deliveryManData'
		},
		{
			name: 'vehicleId',
			source: {
				service: 'tms',
				namespace: 'vehicle',
				method: 'list'
			},
			endpointParameters: [
				{
					name: 'id',
					target: 'filter',
					value: {
						dynamic: 'vehicleId'
					}
				}
			],
			targetField: 'vehicleData'
		}
	],
	target: {
		service: 'tms',
		namespace: 'route',
		method: 'update'
	}
};
